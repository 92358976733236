/*
 * This is for global css usage.
 */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://use.typekit.net/qtg4lpb.css');
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;500;600;700;900&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Noto Sans TC', 'PingFangTC-Regular', 'Microsoft JhengHei', 'Roboto', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	outline: none;
}

body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: 'Noto Sans TC', 'PingFangTC-Regular', 'Microsoft JhengHei', sans-serif;
}

body {
	margin: 0;
	overflow-x: hidden;
	width: 100%;

	&:global(.no-scroll) {
		position: fixed;
		overflow-y: scroll;
	}
}

textarea,
input {
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
	border-radius: 0;

	&::placeholder {
		color: var(--light-gray);
	}
}

input {
	width: 100%;
	border: 0;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--black);

	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
		margin: 0;
	}

	&:-webkit-autofill {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-box-shadow: 0 0 0 30px white inset;
	}

	&[type='number'] {
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-moz-appearance: textfield;
	}
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

:global(.ql-align-justify) {
	text-align: justify;
}

:global(.ql-align-center) {
	text-align: center;
}

:global(.ql-align-left) {
	text-align: left;
}

:global(.ql-align-right) {
	text-align: right;
}

:global(.ql-font-monospace) {
	font-family: 'Zen Kaku Gothic New', sans-serif;

	& > strong,
	& > em,
	& > s {
		font-family: 'Zen Kaku Gothic New', sans-serif;
	}
}

:global(.ql-font-serif) {
	font-family: 'Noto Serif TC', serif;

	& > strong,
	& > em,
	& > s {
		font-family: 'Noto Serif TC', serif;
	}
}

@media print {
	@page {
		size: a4 portrait;
		margin: 40px 0;
	}

	html,
	body {
		width: 794px;
		height: 1123px;

		&:global(.no-scroll) {
			position: absolute;

			/* stylelint-disable-next-line declaration-no-important */
			top: 0 !important;
		}

		& > div:first-child {
			display: none;
		}
	}
}
