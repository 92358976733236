.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	& > .content {
		flex-grow: 1;
		min-height: 100vh;
		position: relative;

		&.with-padding {
			padding-top: 80px;
		}
	}

	& > .fullscreen-cover {
		top: auto;
		left: auto;
		bottom: 32px;
		padding-bottom: 0;
	}

	& > .penetrate {
		pointer-events: none;
	}
}
